import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject, switchMap} from 'rxjs';
import {
  BecomeVolunteerInfo,
  ChangesHistory,
  MatchingEditProfile,
  ParticipantDetails,
  ParticipantProfile,
  SessionCard,
} from '../model/participant-details';
import {UserRole} from '@static/constants/user-role/user-role.enum';
import {MatchHistoryCard} from '../model/match-history-card';
import {AddNewStatusDTO, UnfreezeDTO} from '../model/sessions';

@Injectable({
  providedIn: 'root',
})
export class ParticipantService {
  static baseUrl = '/manager/participants';
  public participantsSubject$ = new Subject();
  public participants$: Observable<any>;

  constructor(private http: HttpClient) {
    this.participants$ = this.participantsSubject$
      .pipe(switchMap((params) => {
        return this.getAll(params);
      }));
  }

  public disableAccount(id: number, roleId: number): Observable<any> {
    return this.http.patch(`${ParticipantService.baseUrl}/${id}/disable/${roleId}`, {});
  }

  public deleteAccount(id: number): Observable<any> {
    return this.http.delete(`${ParticipantService.baseUrl}/${id}/delete`);
  }

  public freezeAccount(id: number, userRole: UserRole, data: AddNewStatusDTO): Observable<any> {
    return this.http.patch(`${ParticipantService.baseUrl}/${id}/add-status/${userRole}`, data);
  }

  public unfreezeAccount(id: number, userRole: UserRole, data: UnfreezeDTO): Observable<any> {
    return this.http.patch(`${ParticipantService.baseUrl}/${id}/unfreeze/${userRole}`, data);
  }

  public getStatuses(): Observable<any> {
    return this.http.get(`${ParticipantService.baseUrl}/statuses`);
  }

  public getStatusTimestamp(id: number, userRole: UserRole): Observable<any> {
    return this.http.get(`${ParticipantService.baseUrl}/${id}/status-timestamp/${userRole}`);
  }

  public updateParticipants(params): void {
    this.participantsSubject$.next(params);
  }

  public getAll(params): Observable<any> {
    return this.http.get(ParticipantService.baseUrl, {params});
  }

  public getParticipantDetails(id: number, userRole?: UserRole): Observable<ParticipantDetails> {
    return this.http.get<ParticipantDetails>(`${ParticipantService.baseUrl}/${id}`, {
      params: {
        role: userRole || ''
      }
    });
  }

  public getBecomeVolunteerInfo(id: number): Observable<BecomeVolunteerInfo> {
    return this.http.get<BecomeVolunteerInfo>(`${ParticipantService.baseUrl}/${id}/become-volunteer-info`);
  }


  public resetBecomeVolunteerInfo(id: number): Observable<any> {
    return this.http.delete<any>(`${ParticipantService.baseUrl}/${id}/become-volunteer-info`);
  }

  public setProfileDetails(id: number, role: UserRole, dto: ParticipantProfile): Observable<any> {
    return role === UserRole.STUDENT
      ? this.http.patch(`${ParticipantService.baseUrl}/${id}/student/profile`, dto)
      : this.http.patch(`${ParticipantService.baseUrl}/${id}/volunteer/profile`, dto);
  }

  public setMatchingProfile(id: number, dto: MatchingEditProfile): Observable<any> {
    return this.http.patch(`${ParticipantService.baseUrl}/${id}/matching-profile`, dto);
  }

  public setComment(id: number, comment: string): Observable<any> {
    return this.http.patch(`${ParticipantService.baseUrl}/${id}/comment`, {comment});
  }

  public getMatchingHistory(id: number): Observable<MatchHistoryCard[]> {
    return this.http.get<MatchHistoryCard[]>(`${ParticipantService.baseUrl}/${id}/matching`);
  }

  public cancelCheckInEmail(id: number): Observable<any> {
    return this.http.patch(`${ParticipantService.baseUrl}/${id}/check-in-email`, null);
  }

  public removeMatch(matchId: number): Observable<any> {
    return this.http.patch(`${ParticipantService.baseUrl}/remove-match`, null, {params: {matchId}});
  }

  changePassword(id: number, password: string): Observable<any> {
    return this.http.patch<any>(`${ParticipantService.baseUrl}/${id}/password`, {password});
  }

  public getChangesHistory(id: number): Observable<ChangesHistory[]> {
    return this.http.get<ChangesHistory[]>(`/api/audit/participant/${id}`);
  }

  public getParticipantSessionHistory(participantId: number): Observable<{sessions: SessionCard[] }> {
    return this.http.get<{ sessions: SessionCard[] }>(`/manager/participants/${participantId}/statistics/sessions`);
  }
}
