<div id="wrapper" *ngIf="sessionHistory; else loading">
  <div class="common-info">
    <div class="common-info-block block-session-count">
      <h4>{{ sessionHistory.totalSessions | nullReplace }}</h4>
      <span class="overline-text">Session count</span>
    </div>
    <div class="common-info-block block-info-block">
      <h5>{{ sessionHistory.completedSessionsTime | minutesToStrTime }}</h5>
      <span class="overline-text">Total Time</span>
    </div>
    <div class="common-info-block block-session-types">
      <div class="completed-sessions">
        <h5>{{ sessionHistory.completedSessions | nullReplace }}</h5>
        <span class="overline-text">Completed</span>
      </div>
      <div class="missed-sessions">
        <h5>{{ sessionHistory.missedSessions | nullReplace }}</h5>
        <span class="overline-text">Missed</span>
      </div>
      <div class="future-sessions">
        <h5>{{ sessionHistory.futureSessions | nullReplace }}</h5>
        <span class="overline-text">Future</span>
      </div>
    </div>
  </div>
  <div class="session-history-filters">
    <form [formGroup]="sessionHistoryFilters">
      <mat-form-field appearance="fill" class="session-history-range">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Start date" class="no-global-input-styles">
          <input matEndDate formControlName="end" placeholder="End date" class="no-global-input-styles">
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="sessionHistoryFilters.controls.start.hasError('matStartDateInvalid')">Invalid start date
        </mat-error>
        <mat-error *ngIf="sessionHistoryFilters.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </mat-form-field>
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Filter by name</mat-label>
        <mat-select formControlName="name">
          <mat-option *ngFor="let attendant of allTimeAttendants" [value]="attendant">
            {{ attendant }}
          </mat-option>
        </mat-select>
        <button *ngIf="sessionHistoryFilters.get('name').value" mat-icon-button matSuffix
                (click)="$event.stopPropagation(); sessionHistoryFilters.get('name').reset()" tabindex="-1"
                aria-label="Clear">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </form>
  </div>
  <div class="container">
    <table class="session-history-table">
      <tbody>
      <tr matTooltip="Edit session"
          (click)="editSession(session)"
          *ngFor="let session of sessionHistory.sessions | sessionFilter: sessionHistoryFilters.value : participant.userRole">
        <td class="caption-text date">
          {{ session.date * 1000 | date:'M/d/yy h:mm a' }}
        </td>
        <td class="body-regular-text time">
          {{ session.minutes }}
        </td>
        <td>
          <mat-icon *ngIf="session.isApproved === null" class="done-icon hidden">done</mat-icon>
          <mat-icon *ngIf="session.isApproved === true" class="done-icon">done</mat-icon>
          <mat-icon *ngIf="session.isApproved === false" class="text-danger">clear</mat-icon>
        </td>
        <td class="attendants">
          <ng-container *ngFor="let person of (participant.userRole === 101 ? session.students : [session.volunteer])">
            <span class="d-block">{{ person.name }}</span>
          </ng-container>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #loading>
  <div class="spinner-center">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
</ng-template>
