import { Pipe, PipeTransform } from '@angular/core';
import {
  SessionCard,
  SessionHistoryAttendant
} from '../../features/participants/manager/model/participant-details';

@Pipe({
  name: 'sessionFilter'
})
export class SessionFilterPipe implements PipeTransform {
  transform(
    sessions: SessionCard[],
    filters: { start?: Date; end?: Date; name?: string },
    userRole: number
  ): SessionCard[] {
    if (!sessions) return [];

    return sessions.filter(session => {
      const sessionDate = new Date(session.date * 1000);

      let dateOk = true;
      if (filters.start) {
        dateOk = sessionDate >= new Date(filters.start);
      }
      if (filters.end) {
        dateOk = dateOk && sessionDate <= new Date(filters.end);
      }

      let nameOk = true;
      if (filters.name && filters.name.trim()) {
        const searchTerm = filters.name.trim().toLowerCase();
        const attendants = userRole === 101 ? session.students : [session.volunteer];
        nameOk = attendants.some((person: SessionHistoryAttendant) =>
          person.name.toLowerCase().includes(searchTerm)
        );
      }

      return dateOk && nameOk;
    });
  }
}
