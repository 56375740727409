import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'minutesToStrTime'
})
export class MinutesToStrTimePipe implements PipeTransform {

  transform(value: number): string {
    if (!value || value === 0) {
      return '-';
    }

    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    if (hours > 0 && minutes > 0) {
      return `${hours}h ${minutes}min`;
    } else if (hours > 0 && minutes === 0) {
      return `${hours}h`;
    } else if (hours === 0 && minutes > 0) {
      return `${minutes}min`;
    }

    return '-';
  }
}
