import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  ParticipantDetails,
  SessionCard, SessionHistory,
  SessionHistoryAttendant
} from '../../../../model/participant-details';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {SessionService} from '../../../../service/session.service';
import {MatDialog} from '@angular/material/dialog';
import {ScheduleSessionComponent} from '../../modal-windows/schedule-session/schedule-session.component';
import {SessionInfoDto} from '../../../../model/sessions';
import {FormControl, FormGroup} from '@angular/forms';
import {UserRole} from '@static/constants/user-role/user-role.enum';

@UntilDestroy()
@Component({
  selector: 'app-participant-session-history',
  templateUrl: './participant-session-history.component.html',
  styleUrls: ['./participant-session-history.component.scss']
})
export class ParticipantSessionHistoryComponent implements OnInit {
  public allTimeAttendants: string[];
  private currentUserRole: number;
  @Input() participant: ParticipantDetails;

  @Input() set sessions(sessions: SessionCard[]) {
    if (!!sessions) {
      this.allTimeAttendants = this.setAllTimeAttendants(sessions);
      this.sessionHistory = this.createSessionHistoryDataSection(sessions);
    }
  }

  @Output() sessionsChanged = new EventEmitter<void>();
  sessionHistory: SessionHistory;
  sessionHistoryFilters = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
    name: new FormControl(),
  });

  constructor(private sessionService: SessionService, private matDialog: MatDialog) {
  }

  ngOnInit() {
    this.currentUserRole = this.participant.userRole;
  }

  editSession(sessionCard: SessionCard): void {
    this.sessionService
      .getSession(this.participant.id, sessionCard.id)
      .pipe(untilDestroyed(this))
      .subscribe((sessionInfoDto: SessionInfoDto) => {
        const ref = this.matDialog.open(ScheduleSessionComponent, {
          data: {
            participantId: this.participant.id,
            participantDetails: this.participant,
            missedAttendants: sessionCard.isApproved === false
              ? this.extractAttendantsThatMissed([...sessionCard.students, sessionCard.volunteer])
              : [],
            session: sessionInfoDto,
          }
        });

        ref.afterClosed()
          .pipe(untilDestroyed(this))
          .subscribe((r) => {
            if (ref) {
              this.sessionsChanged.emit();
            }
          });
      });
  }

  private extractAttendantsThatMissed(allAttendants: SessionHistoryAttendant[]): SessionHistoryAttendant[] {
    return allAttendants.filter((attendant: SessionHistoryAttendant) => attendant.missed);
  }

  private createSessionHistoryDataSection(sessions: SessionCard[]): SessionHistory {
    const sessionHistory = {
      totalSessions: sessions.length,
      missedSessions: sessions.filter((session: SessionCard) => session.isApproved === false).length,
      futureSessions: sessions.filter((session: SessionCard) => session.date * 1000 > Date.now()).length,
      completedSessions: 0,
      completedSessionsTime: sessions.reduce((acc: number, cur: SessionCard) => acc + cur.minutes, 0),
      sessions,
    };
    sessionHistory.completedSessions = sessionHistory.totalSessions - sessionHistory.missedSessions - sessionHistory.futureSessions;

    return sessionHistory;
  }

  private setAllTimeAttendants(sessions: SessionCard[]): string[] {
    const attendants = new Set<string>();
    sessions.forEach((session: SessionCard) => {
      if (this.currentUserRole === UserRole.VOLUNTEER) {
        session.students.forEach((student: SessionHistoryAttendant) => {
          attendants.add(student.name);
        });
      } else {
        attendants.add(session.volunteer.name);
      }
    });

    return [...attendants];
  }
}
