import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditProfileComponent } from '../modal-windows/edit-profile/edit-profile.component';
import { BecomeVolunteerInfo, ChangesHistory, ParticipantDetails, SessionCard } from '../../../model/participant-details';
import { ActivatedRoute } from '@angular/router';
import { ParticipantService } from '../../../service/participant.service';
import { EditCommentComponent } from '../modal-windows/edit-comment/edit-comment.component';
import { ScheduleSessionComponent } from '../modal-windows/schedule-session/schedule-session.component';
import { EditMatchingProfileComponent } from '../modal-windows/edit-matching-profile/edit-matching-profile.component';
import { UserRole } from '../../../../../../static/constants/user-role/user-role.enum';
import {
  ChangePasswordDialogComponent
} from '../../../../../team/component/change-password/change-password-dialog.component';
import { NotifierService } from 'angular-notifier';
import { EditMatchingDetailsComponent } from '../modal-windows/edit-matching-details/edit-matching-details.component';
import { switchMap, tap, Subscription, takeUntil, EMPTY } from 'rxjs';
import { AnswersService } from '../answers.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmWindow } from '../../../../../matching/component/warning-window/confirm-window';
import { ConfirmWindowComponent } from '../../../../../matching/component/warning-window/confirm-window.component';
import { InterviewStatus } from '../../../../../../static/constants/interview-status/interview-status.enum';
import { InterviewDecision } from '../../../../../../static/constants/interview-decision/interview-decision.enum';
import { EditMiniGroupComponent } from '../modal-windows/edit-mini-group/edit-mini-group.component';


@UntilDestroy()
@Component({
  selector: 'app-participant-details',
  templateUrl: './participant-details.component.html',
  styleUrls: ['./participant-details.component.scss']
})
export class ParticipantDetailsComponent implements OnInit, OnDestroy {

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private service: ParticipantService,
    private notifier: NotifierService,
    private cdRef: ChangeDetectorRef,
    public answersService: AnswersService
  ) {
  }

  id: number;
  requestedUserRole?: UserRole;
  participant: ParticipantDetails;
  changes: ChangesHistory[];
  becomeVolunteerInfo?: BecomeVolunteerInfo;
  sessionHistory: SessionCard[];


  get isVolunteer(): boolean {
    return this.currentUserRole === UserRole.VOLUNTEER;
  }

  get isStudent(): boolean {
    return this.currentUserRole === UserRole.STUDENT;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = +params.id;
      this.requestedUserRole = params.userRole;
      this.getParticipantDetails();
      this.getParticipantSessionHistory(+params.id);
    });
  }

  ngOnDestroy(): void {
    this.answersService.reset();
  }

  get currentUserRole(): UserRole {
    return this.requestedUserRole ? +this.requestedUserRole : this.participant?.userRole;
  }

  getParticipantDetails(): void {
    this.service.getParticipantDetails(this.id, this.currentUserRole)
      .pipe(
        untilDestroyed(this),
        tap((participant: ParticipantDetails) => {
          this.participant = participant;
          this.answersService.userRole = this.currentUserRole;

          if (!this.becomeVolunteerInfo && this.showBecomeVolunteerStepsInfo) {
            this.reloadBecomeVolunteerInfo();
          }
        }),
        switchMap(() => this.answersService.getQuestionsData(this.id)),
      )
      .subscribe(() => this.cdRef.detectChanges());

    this.service.getChangesHistory(this.id)
      .pipe(untilDestroyed(this))
      .subscribe(
        (value) => {
          this.changes = value;
        },
      );
  }

  get showBecomeVolunteerStepsInfo(): boolean {
    return this.participant?.userRoles?.includes(UserRole.STUDENT) &&
      !this.participant?.userRoles?.includes(UserRole.VOLUNTEER);
  }

  resetBecomeVolunteerStepsInfo() {
    const data: ConfirmWindow = {
      title: 'Reset Data',
      warning: 'Become volunteer data will be reset. Are you sure to continue?',
      confirmButtonName: 'Reset',
      cancelButtonName: 'Cancel',
    };

    const config = new MatDialogConfig();
    config.width = '50vw';
    config.data = data;

    this.dialog.open(ConfirmWindowComponent, config)
      .afterClosed()
      .pipe(
        switchMap((reset) => reset ? this.service.resetBecomeVolunteerInfo(this.id) : EMPTY),
        switchMap(() => this.service.getBecomeVolunteerInfo(this.id)),
        untilDestroyed(this)
      ).subscribe((result) => this.becomeVolunteerInfo = result);
  }

  get becomeVolunteerStep1Good(): boolean {
    return (this.becomeVolunteerInfo?.answer?.selectedAnswers?.length ?? 0) > 0;
  }

  get becomeVolunteerStep2Good(): boolean {
    switch (this.becomeVolunteerInfo?.interview?.interviewStatus) {
      case InterviewStatus.SCHEDULED:
      case InterviewStatus.RESCHEDULED:
      case InterviewStatus.PENDING_LOG:
      case InterviewStatus.COMPLETED:
        return true;
    }

    return false;
  }

  get interviewCompleted(): boolean {
    return this.becomeVolunteerInfo?.interview?.interviewStatus == InterviewStatus.COMPLETED;
  }

  get becomeVolunteerStep3Good(): boolean {
    return this.interviewCompleted &&
      this.becomeVolunteerInfo?.interview?.decision == InterviewDecision.ACCEPTED;
  }

  reloadBecomeVolunteerInfo() {
    this.service.getBecomeVolunteerInfo(this.id)
      .pipe(untilDestroyed(this))
      .subscribe(
        (value) => this.becomeVolunteerInfo = value,
      );
  }

  editProfile(): void {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.autoFocus = true;
    config.width = '50vw';
    config.data = {
      id: this.id,
      participant: this.participant
    };
    const ref = this.dialog.open(EditProfileComponent, config);
    ref.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(refresh => {
        if (refresh) {
          this.getParticipantDetails();
        }
      });
  }

  editMatching(): void {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.autoFocus = true;
    config.width = '50vw';
    config.data = {
      id: this.id,
      participant: this.participant,
      userRole: this.currentUserRole,
    };
    const ref = this.dialog.open(EditMatchingProfileComponent, config);
    ref.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        if (value) {
          this.getParticipantDetails();
        }
      });
  }

  editComment(): void {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.autoFocus = true;
    config.width = '50vw';
    config.data = {
      id: this.id,
      comment: this.participant.comment
    };
    const ref = this.dialog.open(EditCommentComponent, config);
    ref.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(_ => this.getParticipantDetails());
  }

  matchingHistory(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';
    dialogConfig.data = {
      participant: this.participant
    };
    const ref = this.dialog.open(EditMatchingDetailsComponent, dialogConfig);
    ref.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(_ => this.getParticipantDetails());
  }

  openEditMiniGroupDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';
    dialogConfig.data = {
      participantId: this.id,
      miniGroup: this.participant.miniGroup
    };
    const ref = this.dialog.open(EditMiniGroupComponent, dialogConfig);
    ref.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(_ => this.getParticipantDetails());
  }

  openChangeParticipantPasswordDialog(id: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';
    const ref = this.dialog.open(ChangePasswordDialogComponent, dialogConfig);
    ref.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(password => {
        if (password) {
          this.service.changePassword(id, password).subscribe({
            next: _ => this.notifier.notify('success', 'Updated successfully'),
            error: (e) => this.notifier.notify('error', e.error.message)
          });
        }
      });
  }

  addSession(): void {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.autoFocus = true;
    config.width = '50vw';
    config.data = {
      participantId: this.id,
      participantDetails: this.participant
    };
    const ref = this.dialog.open(ScheduleSessionComponent, config);
    ref.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(_ => this.getParticipantDetails());
  }

  public getParticipantSessionHistory(participantId: number): void {
    this.service.getParticipantSessionHistory(participantId)
      .subscribe((sessionHistory: { sessions: SessionCard[] }) => {
          this.sessionHistory = sessionHistory.sessions;
        },
      );
  }
}
